import { Button } from "@mui/material";

const ButtonCss = ({
  width,
  height,
  label,
  callback,
  mt,
  variant,
  type,
  Icon,
  marginLeft,
  bgcolor,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={callback}
      variant={variant}
      type={type}
      sx={{
        width: width,
        height: height,
        marginTop: mt,
        marginLeft: marginLeft,

        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        color: "white", // Set text color to white for better visibility
      }}
    >
      {Icon}
      {label}
    </Button>
  );
};

export default ButtonCss;
