import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layouts/Layout";
import PrivateUserRoutes from "./utils/PrivateUserRoutes";
import PrivateAdminRoutes from "./utils/PrivateAdminRoutes";
import PublicRoutes from "./utils/PublicRoutes";
import NotFound from "../Pages/NotFound";
import Admin from "../Pages/Admin";
import User from "../Pages/User";
import Login from "../Pages/Login";
import App from "../App";
const AppRoutes = ({ props }) => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route element={<PrivateAdminRoutes />}>
            <Route path="/Admin" element={<Admin />} />
          </Route>
          <Route element={<PrivateUserRoutes />}>
            <Route path="/User" element={<User />} />
          </Route>
          <Route element={<PublicRoutes />}>
            <Route path="/" element={<Login />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default AppRoutes;
