import { useState } from "react";
import UserContext from "./UserContext";
import { getCookie } from "../cookies";

// "https://vouchers-ngn.vercel.app/api",
//"http://localhost:8011/api"

//vouchers-seven.vercel.app
//localhost:3000
const UserProvider = ({ children }) => {
  const [context, setContext] = useState({
    ip: "http://localhost:8011/api",
    // frUrl: "vouchers-seven.vercel.app",
    role: getCookie("role"),
  });
  return (
    <UserContext.Provider value={{ context, setContext }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
