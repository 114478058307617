import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { getCookie, removeCookie } from "../cookies";
import UserContext from "../Context/UserContext";
import { useContext } from "react";
import { useState } from "react";
import { Logout } from "@mui/icons-material";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import {
  Avatar,
  Menu,
  MenuItem,
  ThemeProvider,
  createTheme,
} from "@mui/material";


const theme = createTheme({
  direction: "rtl",
});
const cacheRtl = createCache({ key: "muirtl", stylisPlugins: [rtlPlugin] });

const drawerWidth = 240;
const navItems = [
  
  { name: "admin", route: "/Admin" },
  { name: "admin", route: "/Admin" },
  { name: "", route: "/Admin" },
];

function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const [open, setOpen] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const { context, setContext } = useContext(UserContext);
  const [data, setData] = useState(JSON.parse(getCookie("user_data")));

  const handleLogout = () => {
    setContext({ ...context, role: "" });
    removeCookie("jwt");
    removeCookie("name");
    removeCookie("role");
    removeCookie("id");
    navigate("/");
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {data.username}
      </Typography>

      <Divider />
      <List>
        {navItems.map((item, key) => (
          <ListItem
            onClick={() => {
              navigate(item.route);
            }}
            key={key}
            disablePadding
          >
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
        {/* <ListItem
          onClick={() => {
            setOpen(true);
          }}
          disablePadding
        >
          <ListItemButton sx={{ textAlign: "center" }}>
            <ListItemText primary="اضافة مستخدم" />
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={() => {
            setOpenReset(true);
          }}
          disablePadding
        >
          <ListItemButton sx={{ textAlign: "center" }}>
            <ListItemText primary="تعديل الملف الشخصي" />
          </ListItemButton>
        </ListItem> */}
        <IconButton onClick={handleLogout} sx={{ color: "black" }}>
          <Logout />
        </IconButton>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={cacheRtl}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar dir="rtl" component="nav">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
              >
                {data.username}
              </Typography>
              <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                {navItems.map((item, key) => (
                  <Button
                    onClick={() => {
                      navigate(item.route);
                    }}
                    key={key}
                    sx={{ color: "#fff" }}
                  >
                    {item.name}
                  </Button>
                ))}
                {/* <div>
                  <Avatar
                    sx={{ cursor: "pointer" }}
                    alt="Profile Picture"
                    onClick={handleClick}
                  />
                  <Menu
                    dir="rtl"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <Button variant="text" color="primary">
                        اضافة مستخدم
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        onClick={() => {
                          setOpenReset(true);
                        }}
                        variant="text"
                        color="primary"
                      >
                        تعديل الملف الشخصي
                      </Button>
                    </MenuItem>
                  </Menu>
                </div> */}
                <IconButton onClick={handleLogout} sx={{ color: "white" }}>
                  <Logout />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <nav>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
          <Box component="main" sx={{ p: 3 }}>
            <Toolbar />
          </Box>
        </Box>
        
      </CacheProvider>
    </ThemeProvider>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;
