import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { TextField } from "@mui/material";
import ButtonCss from "../Components/Reusables/ButtonCss";

import UserContext from "../Components/Context/UserContext";
import Swal from "sweetalert2";
import { setCookie } from "../Components/cookies";
import ThemeDirection from "../Components/ThemeDirection";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { context, setContext } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) {
      Swal.fire({
        icon: "error",
        title: "Login Error",
        text: "Please enter both email and password.",
      });
      return;
    }

    try {
      const response = await fetch(`${context.ip}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        const { token, user } = responseData;
        setContext({ ...context, role: user.role, client: user.ClientName });
        setCookie("jwt", token);
        setCookie("role", user.role);
        setCookie("id", user.id);
        setCookie("user_data", JSON.stringify(user));
        user.role === "admin"
          ? navigate("/Admin")
          : user.role === "user"
          ? navigate("/User")
          : navigate("/");
      } else {
        throw new Error(responseData.error);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Login Error",
        text: error.message,
      });
    }
  };

  return (
    <ThemeDirection>
      <Box
        sx={{
          width: "100%",
          height: "100vh", // Ensure it takes the full viewport height
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container>
          <Box
            style={{ direction: "rtl" }}
            component="form"
            onSubmit={(e) => e.preventDefault()}
          >
            <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid xs={12} sm={8} md={6} container item>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={5}
                  item
                  md={12}
                  xs={12}
                  sm={12}
                >
                  <TextField
                    mt={3}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label="ايميل"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email || ""}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ width: "75%" }}
                  />
                </Grid>
                <Grid
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  item
                  md={12}
                  xs={12}
                  sm={12}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="password"
                    label="رمز المرور"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password || ""}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ width: "75%" }}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent=" center"
                  md={12}
                  xs={12}
                  sm={12}
                >
                  <ButtonCss
                    {...{
                      label: "دخول",
                      mt: 2,
                      variant: "contained",
                      width: "50%",
                      type: "submit",
                      callback: handleLogin,
                    }}
                  >
                    <LockOutlinedIcon />
                  </ButtonCss>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </ThemeDirection>
  );
};

export default Login;
