import { Outlet, Navigate } from 'react-router-dom'
import { getCookie } from '../cookies'

const PrivateUserRoutes = () => {
    let auth = { 'token': !!getCookie("jwt") }
    return (
        auth.token && getCookie("role") === "user" ? <Outlet /> : <Navigate to="/" />
    )
}

export default PrivateUserRoutes