import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { removeCookie } from "../cookies";
import UserContext from "../Context/UserContext";
import { useContext } from "react";
import { useState } from "react";
import { AddOutlined, DockOutlined, Home, Logout } from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { Menu, MenuItem, ListItemIcon, Tooltip } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

const theme = createTheme({
  direction: "rtl",
});
const cacheRtl = createCache({ key: "muirtl", stylisPlugins: [rtlPlugin] });

const drawerWidth = 240;

const navItems = [
  { name: "user", route: "/User" },
  { name: "user", route: "/User" },
  { name: "user", route: "/User" },
];
function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const { context, setContext } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setContext({ ...context, role: "" });
    removeCookie("jwt");
    removeCookie("name");
    removeCookie("role");
    removeCookie("id");
    navigate("/");
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {context.client}
      </Typography>

      <Divider />
      <List sx={{ display: "flex", flexDirection: "column" }}>
        <Button
          onClick={() => {
            navigate("/UserPage");
            setAnchorEl(null);
          }}
        >
          الصفحة الرئيسية
          <Home sx={{ mr: 2 }} />
        </Button>
        <Button
          onClick={() => {
            navigate("/UserCoupons");
            setAnchorEl(null);
          }}
          sx={{ mt: 2 }}
        >
          القسائم المتاحة
          <DockOutlined sx={{ mr: 3 }} />
        </Button>

        <Button
          onClick={() => {
            navigate("/AddVoucher");
            setAnchorEl(null);
          }}
          sx={{ mt: 2 }}
        >
          اصدار قسيمة
          <AddOutlined sx={{ mr: 3 }} />
        </Button>

        <Tooltip title={"تسجيل الخروج"}>
          <Button onClick={handleLogout} sx={{ color: "black" }}>
            تسجيل الخروج
            <Logout sx={{ margin: "10px" }} fontSize="small" />
          </Button>
        </Tooltip>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={cacheRtl}>
        <Box sx={{ direction: "rtl", display: "flex" }}>
          <CssBaseline />
          <AppBar
            dir="rtl"
            component="nav"
            sx={{
            
              backgroundSize:
                "cover" /* Ensure the background covers the entire AppBar */,
              backgroundRepeat:
                "no-repeat" /* Prevent background from repeating */,
              backgroundAttachment: "fixed",
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "none" } }}
              >
                <MenuIcon />
              </IconButton>
              
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
              >
                {context.client}
              </Typography>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  {/* <div>
                    <IconButton color="inherit" onClick={handleClick}>
                      <AccountCircleIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          setOpen(true);
                          setAnchorEl(null);
                        }}
                      >
                        <ListItemIcon>
                          <VpnKeyIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="تغيير كلمة السر" />
                      </MenuItem>
                      {/* You can add more menu items here if needed */}
                  {/* </Menu> */}
                  {/* </div> */}
                  {navItems.map((item, key) => (
                    <Button
                      onClick={() => {
                        navigate(item.route);
                      }}
                      key={key}
                      sx={{ color: "#fff" }}
                    >
                      {item.name}
                    </Button>
                  ))}
                  <IconButton onClick={handleLogout} sx={{ color: "white" }}>
                    <Logout />
                  </IconButton>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
          <nav>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
          <Box component="main" sx={{ p: 3 }}>
            <Toolbar />
          </Box>
        </Box>
      </CacheProvider>
    </ThemeProvider>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;
