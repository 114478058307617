import React, { useEffect, useRef, useState } from "react";

const User = () => {
  const videoRef = useRef(null);
  const adContainerRef = useRef(null);
  const [adsLoader, setAdsLoader] = useState(null);
  const [adsManager, setAdsManager] = useState(null);

  useEffect(() => {
    // Dynamically load the IMA SDK if it's not already available
    if (!window.google || !window.google.ima) {
      const script = document.createElement("script");
      script.src = "https://imasdk.googleapis.com/js/sdkloader/ima3.js";
      script.onload = initializeIMA;
      document.body.appendChild(script);
    } else {
      initializeIMA();
    }
  }, []);

  const initializeIMA = () => {
    const adDisplayContainer = new window.google.ima.AdDisplayContainer(
      adContainerRef.current,
      videoRef.current
    );
    const adsLoader = new window.google.ima.AdsLoader(adDisplayContainer);
    setAdsLoader(adsLoader);

    adsLoader.addEventListener(
      window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      onAdsManagerLoaded,
      false
    );
    adsLoader.addEventListener(
      window.google.ima.AdErrorEvent.Type.AD_ERROR,
      onAdError,
      false
    );

    requestAds();
  };

  const requestAds = () => {
    const adsRequest = new window.google.ima.AdsRequest();
    adsRequest.adTagUrl = "YOUR_AD_TAG_URL_HERE";
    adsRequest.linearAdSlotWidth = 640;
    adsRequest.linearAdSlotHeight = 360;
    adsLoader.requestAds(adsRequest);
  };

  const onAdsManagerLoaded = (adsManagerLoadedEvent) => {
    const adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
    const adsManager = adsManagerLoadedEvent.getAdsManager(
      videoRef.current,
      adsRenderingSettings
    );
    setAdsManager(adsManager);
    adsManager.init(640, 360, window.google.ima.ViewMode.NORMAL);
    adsManager.start();
  };

  const onAdError = (error) => {
    console.error("Ad error:", error.getError().toString());
  };

  const playAd = () => {
    if (!adsManager) {
      console.error("AdsManager is not ready or failed to load.");
      // Handle this situation (e.g., disable the play button or show a message)
      return;
    }

    adContainerRef.current.style.display = "block";
    adsManager.init(640, 360, window.google.ima.ViewMode.NORMAL);
    adsManager.start();
  };

  return (
    <div>
      <div
        ref={adContainerRef}
        style={{ width: "640px", height: "360px", display: "none" }}
      />
      <video
        ref={videoRef}
        style={{ width: "640px", height: "360px" }}
        controls
      />
      <button onClick={playAd}>Watch Ad to Earn Points</button>
    </div>
  );
};

export default User;
