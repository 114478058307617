import { Outlet, Navigate } from "react-router-dom";
import { getCookie } from "../cookies";

const PublicRoutes = () => {
  let auth = { token: !!getCookie("jwt") };
  return auth.token && getCookie("role") === "user" ? (
    <Navigate to="/User" />
  ) : auth.token && getCookie("role") === "admin" ? (
    <Navigate to="/Admin" />
  ) : (
    <Outlet />
  );
};

export default PublicRoutes;
