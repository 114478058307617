import { useContext } from "react";
import Navbar from "../Navbar/Navbar";
import UserContext from "../Context/UserContext";
import UserNavbar from "../Navbar/UserNavbar";

const Layout = ({ children }) => {
  const { context, setContext } = useContext(UserContext);
  return (
    <>
      {context.role === "admin" ? (
        <>
          <Navbar />
          {children}
        </>
      ) : context.role === "user" ? (
        <>
          <UserNavbar />
          {children}
        </>
      ) : (
        children
      )}
    </>
  );
};

export default Layout;
