import logo from "./logo.svg";
import "./App.css";
import VideoAd from "./Components/Video";
import ThemeDirection from "./Components/ThemeDirection";
import UserProvider from "./Components/Context/UserProvider";
import AppRoutes from "./Components/AppRoutes";

function App() {
  return (
    <ThemeDirection>
      <UserProvider>
        <AppRoutes />
      </UserProvider>
    </ThemeDirection>
  );
}

export default App;
